import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class WebapiserviceService {
  baseUrl = environment.url.baseUrl;

  constructor(private http: HttpClient) { }

  get(url: string): Observable<any> {
    const headers = this.setHeader();
    const options = { headers: headers };

    return this.http.get(this.baseUrl + url, options);
  }

  post(url: string, json: any): Observable<any> {
    const headers = this.setHeader();
    const options = { headers: headers };

    return this.http.post(this.baseUrl + url, json, options);
  }
  put(url: string, json: any): Observable<any> {
    const headers = this.setHeader();
    const options = { headers: headers };

    return this.http.put(this.baseUrl + url, json, options);
  }

  delete(url: string, json?: any): Observable<any> {
    const headers = this.setHeader();
    const options = { headers: headers };

    return this.http.delete(this.baseUrl + url, options);
  }

  fordataPost(url: string, json: any): Observable<any> {
    const headers = this.setFormHeader();
    const options = { headers: headers };

    return this.http.post(this.baseUrl + url, json, options);
  }

  private setHeader(): HttpHeaders {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    const token = this.getToken();
    if (token) {
      headers = headers.set('x-access-token', token);
    }

    return headers;
  }

  private setFormHeader(): HttpHeaders {
    let headers = new HttpHeaders();

    const token = this.getToken();
    if (token) {
      headers = headers.set('x-access-token', token);
    }

    return headers;
  }

  private getToken(): string | null {
    if (localStorage.getItem('admin_details')) {
      return localStorage.getItem('token');
    } else if (localStorage.getItem('admin_forgot')) {
      return localStorage.getItem('admin_forgot');
    } else if (localStorage.getItem('confirm_token')) {
      return localStorage.getItem('confirm_token');
    } else {
      return null;
    }
  }
}