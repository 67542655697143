export const environment = {
  production: false,
  url: {
    baseUrl: 'https://api.housepark.app/v1',
    imgUrl:  'https://api.housepark.app/',
  },
};

// export const environment = {
//   production: false,
//   url: {
//     baseUrl: 'http://localhost:5008/v1',
//     imgUrl:  'http://localhost:5008/',
//   },
// };