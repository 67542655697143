<nb-layout>
    <nb-layout-column>
        <nb-card>
            <nb-card-body>
                <div style="width: calc(100% - 15px);">
                    <div class="row">
                        <div class="col-lg-6 login-background">
                            <img src="./assets/images/login_bg.jpg" alt="Img" width="100%" />
                        </div>
                        <div class="col-lg-6 col-12 responsive-login-background">
                            <div class="login-parkign-logo">
                                <img src="./assets/images/logo.svg" alt="Car" />
                            </div>
                            <div class="login-title">
                                Confirm New Password
                            </div>
                            <div class="login-desc">
                                Hello! Change your password
                            </div>
                            <form class="login-form" autocomplete="off" #f="ngForm" (ngSubmit)="SubmitLogin(f)"
                                novalidate>
                                <div class="row">
                                    <div class="col-12">
                                        <label>Enter New Passowrd</label>
                                        <input type="password" nbInput placeholder="New Password" [(ngModel)]="password_form.new_password" (ngModelChange)="modelChanged($event)"
                                            name="new_pass" required />
                                        <span *ngIf="password_form.new_password == '' && checkRequiredField">New password is required field.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <label>Confirm Password</label>
                                        <input type="password" nbInput placeholder="Password" [(ngModel)]="password_form.confirm_password" (ngModelChange)="modelChanged($event)"
                                            name="confirm_password" required #confPass />
                                        <span *ngIf="password_form.confirm_password == '' && checkRequiredField">Confirm Password is required field.</span><br>
                                        <span *ngIf="password_form.confirm_password != password_form.new_password && confirmFlag">Confirm Password is not matching.</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <button nbButton class="btn-login" [disabled]="buttonloader">Change
                                            Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </nb-layout-column>
</nb-layout>